import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Box } from '@material-ui/core'
import classNames from 'classnames'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  fullWidthMultiImage: {
    padding: '16px 48px',
  },
  image: {
    display: 'flex',
    paddingBottom: '8px',
    justifyContent: 'center',
    '& .gatsby-image-wrapper': {
      width: '100%',
      height: '100%',
    },
  },
  bottomAligned: {
    ' &:last-child': {
      paddingBottom: '0px',
    },
  },
}))

const ImagesModule = (props) => {
  const classes = useStyles()
  const { images, isFullWidth, isBottomAligned } = props.blok

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer parentWidth={isFullWidth}>
          <Box
            className={classNames(classes.boxWrapper, {
              [classes.fullWidthMultiImage]: images.length > 1 && isFullWidth,
            })}
          >
            <Box>
              {!!images &&
                !!images.length &&
                images.map((blok) => {
                  return (
                    <Box
                      key={blok._uid}
                      xs={12 / images.length}
                      className={classNames(classes.image, {
                        [classes.bottomAligned]: isBottomAligned,
                      })}
                    >
                      {renderBlok({
                        ...blok,
                        maxWidth: isFullWidth ? '100%' : 600,
                      })}
                    </Box>
                  )
                })}
            </Box>
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default ImagesModule
